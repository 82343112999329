import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";

import { faPen, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "~/hooks/useClickOutside";
import React, { useEffect, useRef, useState } from "react";
import { type ParsedAccount, getAvatar } from "~/utils/hive";
import { uploadImage } from "~/utils/images";
import { flip, offset, useClick, useDismiss, useFloating, useInteractions, useRole } from "@floating-ui/react";
import classNames from "classnames";
import { useAppStore } from "~/store";
import { useRouteLoaderData } from "@remix-run/react";
import { loadTranslator } from "~/utils/loadTranslator";

interface ModalProps {
  visibility: boolean;
  setVisibility: (visibility: boolean) => void;
  account: ParsedAccount;
  onSubmit: (data: any) => void;
}

interface AccountState {
  name: string;
  profile_image?: string;
  cover_image?: string;
  about: string;
  website: string;
  location: string;
  birthday: string | null;
}

const MONTH_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export function EditProfileModal({ visibility, setVisibility, account, onSubmit }: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  const t = loadTranslator(useRouteLoaderData("root").translations)
  
  const [formData, setFormData] = useState<AccountState>({
    name: "",
    cover_image: "",
    profile_image: "",
    about: "",
    website: "",
    location: "",
    birthday: null
  });

  const [birthDates, setBirthDates] = React.useState({
    day: null,
    month: null,
    year: null
  });

  let posting_json_metadata;

  try {
    posting_json_metadata =
      typeof account.posting_json_metadata === "string"
        ? JSON.parse(account.posting_json_metadata)?.profile
        : account.posting_json_metadata;
  } catch {
    console.error("Error parsing metadata");
  }

  const displayName = posting_json_metadata?.name ?? account.name;
  const coverImage = formData.cover_image || posting_json_metadata?.cover_image;
  const profileImage =
    formData.profile_image || getAvatar(account?.name, "medium") || posting_json_metadata?.profile_image;

  const about = posting_json_metadata?.about;
  const website = posting_json_metadata?.website;
  const location = posting_json_metadata?.location;
  const birthday = posting_json_metadata?.birthday;

  // set initial form data
  useEffect(() => {
    setFormData({
      name: displayName,
      about: about ?? "",
      website: website ?? "",
      location: location ?? "",
      birthday: birthday ?? "",
      profile_image: profileImage ?? "",
      cover_image: coverImage ?? ""
    });

    if (birthday) {
      setBirthDates({
        day: Number(birthday.split(".")[1]),
        month: Number(birthday.split(".")[0]),
        year: Number(birthday.split(".")[2])
      });
    }
  }, [displayName, about, website, location, birthday]);

  useEffect(() => {
    if (Object.values(birthDates)?.some(value => value === null)) {
      setFormData(current => ({ ...current, birthday: null }));
      return;
    }

    const addLeadingZero = (value: number) => (value < 10 ? `0${value}` : value);

    setFormData(current => ({
      ...current,
      birthday: `${addLeadingZero(birthDates.month! + 1)}.${addLeadingZero(birthDates.day!)}.${addLeadingZero(
        birthDates.year!
      )}`
    }));
  }, [birthDates]);

  // handle input change
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // handle form submit
  const handleSubmit = () => {
    const payload = JSON.stringify({
      ...formData,
      profile: {
        ...formData,
        // profile_image: formData?.profile_image || "",
        // cover_image: formData?.cover_image || "",
        version: 2
      }
    });

    onSubmit(payload);
  };

  // not working!
  useOnClickOutside(modalRef, () => setVisibility(false));

  const [profilePictureLoading, setProfilePictureLoading] = React.useState<boolean>(false);
  const [coverLoading, setCoverLoading] = React.useState<boolean>(false);

  const insertImage = (url: string, type: string) => {
    if (type === "profile") {
      setFormData(prev => ({
        ...prev,
        profile_image: url
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        cover_image: url
      }));
    }
  };

  const _account = useAppStore(store => store.account);
  const { activeAccount, keys } = _account;
  const { signature, publicKey, proxy } = keys;

  const handleImageSubmit = async (_file?: File | null, _type: string) => {
    const file = _file.target.files[0];
    const prepareFile = file;

    _type === "profile" ? setProfilePictureLoading(true) : setCoverLoading(true);

    if (prepareFile) {
      const authorization = {
        public_key: publicKey,
        account: activeAccount?.name,
        signature: signature,
        hivesigner: proxy === "hivesigner"
      };
      const response = uploadImage(prepareFile, _account);

      response
        .then(({ url }: any) => {
          insertImage(url as string, _type);
          _type === "profile" ? setProfilePictureLoading(false) : setCoverLoading(false);
        })
        .catch(error => {
          console.log(error);
          _type === "profile" ? setProfilePictureLoading(false) : setCoverLoading(false);
        });
    }
  };

  const handleBirthdayChange = (type: "day" | "month" | "year", value: number) => {
    setBirthDates(current => ({ ...current, [type]: value }));
  };

  if (!visibility) return null;
  return (
    <FloatingHTMLOverlay
      ref={modalRef}
      onClick={ev => ev.stopPropagation()}
      className="z-[1000] flex justify-center py-[6vh]"
      lockScroll
    >
      <div className="animate-reveal-form relative py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-10/12 h-fit pc:w-3/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full tbl:min-w-[420px] drop-shadow-lg shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)]">
        <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-zinc-700/[.75]">
          <strong className="text-lg">{t("edit-profile")}</strong>
          <div
            onClick={() => setVisibility(false)}
            className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </header>

        <div className="flex flex-col gap-y-3">
          <div className="flex flex-col justify-center items-center">
            {/* bg img */}
            <label
              htmlFor="profile-cover"
              className="relative flex bg-gray-100 dark:bg-zinc-800 rounded-lg w-full h-32 overflow-hidden"
            >
              {coverLoading && (
                <span className="absolute inset-0 w-full h-full flex items-center justify-center bg-black/50">
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin text-white" />
                </span>
              )}

              {coverImage && <img src={coverImage} alt="" className="w-full h-full" />}

              <span className="absolute inset-0 flex items-center justify-center rounded-lg bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-150 cursor-pointer">
                <span className="-mt-2">
                  <FontAwesomeIcon icon={faPen} size="lg" className="text-white" />
                </span>
              </span>

              <input onChange={e => handleImageSubmit(e, "cover")} type="file" id="profile-cover" className="hidden" />
            </label>

            {/* profile photo */}
            <label
              htmlFor="profile-photo"
              className="relative flex rounded-full bg-gray-300 dark:bg-gray-500 border-4 border-pri dark:border-[#18181b] w-24 h-24 -mt-10 z-10 overflow-hidden"
            >
              {profilePictureLoading && (
                <span className="absolute inset-0 w-full h-full flex items-center justify-center bg-black/50">
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin text-white" />
                </span>
              )}
              {profileImage && <img src={profileImage} alt="" className="w-full h-full" />}
              <span className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-150 cursor-pointer">
                <FontAwesomeIcon icon={faPen} size="lg" className="text-white" />
              </span>

              <input
                onChange={e => handleImageSubmit(e, "profile")}
                type="file"
                id="profile-photo"
                className="hidden"
              />
            </label>
          </div>

          <div className="flex flex-col gap-y-2">
            <label htmlFor="from" className="text-sm text-zinc-400">
              {t("name")}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="py-2.5 px-3.5 text-sm rounded-lg bg-transparent border border-pri dark:border-pri-d outline-2 outline-offset-4 outline-transparent focus:outline-pri dark:focus:outline-pri-d transition-colors duration-150"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <label htmlFor="from" className="text-sm text-zinc-400">
              {t("about")}
            </label>
            <textarea
              id="about"
              name="about"
              className="py-2.5 px-3.5 text-sm rounded-lg bg-transparent border border-pri dark:border-pri-d outline-2 outline-offset-4 outline-transparent focus:outline-pri dark:focus:outline-pri-d transition-colors duration-150 resize-none"
              value={formData.about}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <label htmlFor="from" className="text-sm text-zinc-400">
              {t("location")}
            </label>
            <input
              type="text"
              id="location"
              name="location"
              className="py-2.5 px-3.5 text-sm rounded-lg bg-transparent border border-pri dark:border-pri-d outline-2 outline-offset-4 outline-transparent focus:outline-pri dark:focus:outline-pri-d transition-colors duration-150"
              value={formData.location}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <label htmlFor="from" className="text-sm text-zinc-400">
              {t("birthday")}
            </label>
            <BirthdayPicker
              day={birthDates.day}
              month={birthDates.month}
              year={birthDates.year}
              onChange={handleBirthdayChange}
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <label htmlFor="from" className="text-sm text-zinc-400">
              {t("website")}
            </label>
            <input
              type="text"
              id="website"
              name="website"
              className="py-2.5 px-3.5 text-sm rounded-lg bg-transparent border border-pri dark:border-pri-d outline-2 outline-offset-4 outline-transparent focus:outline-pri dark:focus:outline-pri-d transition-colors duration-150"
              value={formData.website}
              onChange={handleChange}
            />
          </div>
        </div>

        <footer className="flex justify-end gap-x-2">
          <button
            className="py-2.5 px-5 rounded-full bg-transparent border border-pri dark:border-pri-d hover:bg-pri-d/10 dark:hover:bg-pri/10 text-sm font-medium transition-colors"
            onClick={() => setVisibility(false)}
          >
            {t("website")}
          </button>
          <button
            onClick={() => handleSubmit()}
            className="py-2.5 px-5 rounded-full bg-acc text-sm font-medium hover:opacity-80 transition-opacity"
          >
            {t("save-changes")}
          </button>
        </footer>
      </div>
    </FloatingHTMLOverlay>
  );
}

function BirthdayPicker({
  day,
  month,
  year,
  onChange
}: {
  day: number | null;
  month: number | null;
  year: number | null;
  onChange: (type: "day" | "month" | "year", value: number) => void;
}) {
  return (
    <div className="flex flex-1 items-center gap-x-1.5">
      <DatePickerDropdown type="day" value={day} onChange={onChange} />
      <DatePickerDropdown type="month" value={month} onChange={onChange} />
      <DatePickerDropdown type="year" value={year} onChange={onChange} />
    </div>
  );
}

function DatePickerDropdown({
  type,
  value,
  onChange
}: {
  type: "day" | "month" | "year";
  value: number | null;
  onChange: (type: "day" | "month" | "year", value: number) => void;
}) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: open => setOpen(open),
    middleware: [offset(5), flip()],
    placement: "top",
    strategy: "fixed"
  });

  const click = useClick(context, {
    event: "click"
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context, {
    ancestorScroll: false
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);

  const list = {
    day: [...Array.from({ length: 31 }, (_, index) => index + 1)],
    month: [...Array(12).keys()],
    year: Array.from({ length: new Date().getFullYear() - 1940 }, (_, index) => 1940 + index)
  };

  return (
    <React.Fragment>
      <button
        type="button"
        ref={reference}
        {...getReferenceProps}
        onClick={() => setOpen(current => !current)}
        className={classNames(
          "flex flex-1 py-2.5 px-3.5 text-sm rounded-lg bg-transparent border border-pri dark:border-pri-d outline-2 outline-offset-4 outline-transparent focus:outline-pri dark:focus:outline-pri-d transition-colors duration-150 hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075]",
          {
            "text-pri/50 dark:text-pri-d/50": value === null,
            "text-pri dark:text-pri-d": value !== null,
            "bg-pri-d/[.075] dark:bg-pri/[.075]": open
          }
        )}
      >
        <span className="first-letter:uppercase">
          {value !== null ? (type === "month" ? MONTH_LIST[value] : value) : type}
        </span>
      </button>

      {open && (
        <div
          className="hidden sm:flex flex-col justify-start items-start bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-xl text-xs z-[1000] overflow-hidden drop-shadow-md min-w-[100px] shadow-[0_0_12px_3px_rgb(255_255_255_/_12%)]"
          ref={floating}
          style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
          {...getFloatingProps()}
          tabIndex={-1}
        >
          <div className="flex flex-1 flex-col w-full max-h-[300px] overflow-auto">
            {list[type].map(i => (
              <button
                key={i}
                type="button"
                className="flex flex-1 w-full py-1.5 px-2.5 border-b border-pri dark:border-pri-d last:border-b-0 hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium text-center transition-colors duration-150 cursor-pointer"
                onClick={() => {
                  onChange(type, i);
                  setOpen(false);
                }}
              >
                {type === "month" ? MONTH_LIST[i] : i}
              </button>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

